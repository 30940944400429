<template>
  <div
    class="r-snake-animation flex flex-row"
    :style="'width: ' + fullSnakeLength + 'px'"
  >
    <div
      v-for="index in totalSteps"
      :key="index"
      class="r-snake-animation__food ml-3"
      :class="foodBackgroundColor"
    ></div>
    <div
      class="r-snake-animation__snake"
      :style="'width: ' + currentSnakeLength + 'px'"
    >
      <div class="r-snake-animation__snake__snake_eye"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RSnakeAnimation',
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
    totalSteps: {
      type: Number,
      default: 4,
    },
    foodBackgroundColor: {
      type: String,
      default: 'bg-white',
    },
  },
  computed: {
    fullSnakeLength() {
      return 4 + (16 * this.totalSteps - 1);
    },
    currentSnakeLength() {
      return 4 + 16 * this.currentStep;
    },
  },
};
</script>
<style scoped lang="scss">
.r-snake-animation {
  transition: opacity 2s;

  &__food {
    @apply h-1.5 w-1 rounded-full static;
  }

  &__snake {
    @apply bg-rendin-500 h-2 rounded-xl absolute;
    width: auto;
    transition: width 0.5s;

    &__snake_eye {
      @apply bg-white h-0.5 w-0.5 rounded-full relative;
      left: calc(100% - 6px);
      top: 1px;
    }
  }
}
</style>
